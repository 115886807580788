<template>

    <b-overlay
      :show = "this.loading"
      spinner-variant="primary"
    >
      <b-modal
        id="modal-center"
        centered
        :title="$t('EditPrice')"
        :ok-title="$t('submit')"
        :cancel-title="$t('cancel')"
        :ok-disabled="validationErrors"
        @ok="validateNewPrice()"
      >
        <b-card
            :title="productToEdit? productToEdit.name : ''"
        >
          <validation-observer ref="newPrice">
            <b-form-group
                :label="$t('price')"
            >
              <validation-provider 
                #default="{ errors }" 
                name="Price" 
                rules="required|price"
                >
                <b-form-input
                  v-model="productToEdit.price"
                />
                <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
            </b-form-group>
            </validation-observer>
        </b-card>
      </b-modal>

      <!-- Table Container Card -->
      <b-card
        no-body
      >
    
        <div class="m-2">
    
          <!-- Table Top -->
          <b-row>
    
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>{{ $t('Εγγραφές') }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
             
            </b-col>
    
            <!-- Search -->
            <b-col
              cols="12"
              md="6"
            >
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  type="search"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Αναζήτηση...')"
                />
                
              </div>
            </b-col> 
          </b-row>
    
        </div>
    
        <b-table
          :key = "keykey"
          ref="refProductsTable"
          :items="products"
          responsive
          :fields="tableColumns"  
          :filter="searchQuery"
          primary-key="number"
          :sort-by.sync="sortBy"
          show-empty
          :empty-text="$t('NotFound' )+ ' ' + $t('Products')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
          :no-provider-sorting="true"
          :no-provider-paging="true"
          :no-provider-filtering="true"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mr-1"></b-spinner>
              <strong>{{ $t('Loading') }}...</strong>
            </div>
          </template>

          <!-- Column: Active -->
          <template #cell(active)="data">
            <b-form-checkbox
              :checked="products.find(x=>x.id==data.item.id).active"
              switch
              @change="validateChangeActive(data.item.id)"
              style = "display: block; margin-left: auto; margin-right: auto; width: 20px;"
            />      
          </template>

          <template #cell(edit)="data">
            <b-col class="text-nowrap"  cols="4">
              <feather-icon
                :id="`order-row-${data.index}-edit-icon`"
                icon="EditIcon"
                stroke = "#00397A"            
                size="16"
                @click.stop="editProduct(data.item.id)"
                v-b-modal.modal-center
                class="cursor-pointer"
                />
                <b-tooltip
                  :title="$t('Edit')"
                  placement = "left"
                  variant="primary"
                  :target="`order-row-${data.index}-edit-icon`"
                />
            </b-col>
          </template>

          <template #cell(sku)="data">
            <div style="font-size: 0.9rem;">
              {{data.item.sku}}
            </div>
          </template>
    
    
        </b-table>
    
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">{{$t("Showing")}} {{ from }} {{$t("to")}} {{ to }} {{$t("of")}} {{ of }} {{$t("entries")}}</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
    
              <b-pagination
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                :per-page="perPage"
                v-model="currentPage"
                first-number
                last-number
                :total-rows="of"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
    
            </b-col>
    
          </b-row>
        </div>
      </b-card>
    
    </b-overlay>
    </template>
    
    <script>
    import {
      BCard, BRow, BCol, BFormInput, BFormGroup, BButton, BTable, BMedia, BLink, BFormCheckbox,
      BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BSpinner, BOverlay,  BModal, VBModal, BIconNodePlusFill 
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import store from '@/store'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import moduleUser from '@/store/user/moduleUser.js'
    import moduleCreditTerms from '@/store/credit-terms/moduleCreditTerms.js'
    import moduleOffer from '@/store/offers/moduleOffer.js'
    import moduleProducts from '@/store/catalog/moduleProducts.js'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, price } from '@supplier-interface-validations'

    export default {
      components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormGroup,
        BButton,
        BTable,
        BMedia,
        BModal,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BPagination,
        BTooltip,
        BSpinner,
        BOverlay,
        vSelect,
        moduleCreditTerms,
        moduleUser,
        moduleOffer,
        moduleProducts,
        ToastificationContent,
        ValidationProvider,
        ValidationObserver
      },
      directives: {
        'b-modal': VBModal,
      },
      data() {
        return {
          validationErrors: false,
          required,
          price,
          productToEdit: {price:0},
          productToEditId: 0,
          keykey:0,
          products:[],
          actives:[],
          loading: true,
          user: null,
          tableColumns : [
            { key: 'sku', label: 'SKU' ,sortable: true },
            { key: 'name',  label: 'ΌΝΟΜΑ', sortable: true },
            { key: 'category',  label: 'ΚΑΤΗΓΟΡΙΑ', sortable: true },
            { key: 'active' , label: 'ΕΝΕΡΓΟ'},
            { key: 'price',  label: 'ΤΙΜΗ(\u20AC)', sortable: true },
            { key: 'edit' , label: 'ΕΠΕΞΕΡΓΑΣΙΑ ΤΙΜΗΣ'},],
          perPage : 10,
          currentPage: 1,
          perPageOptions : [10, 25, 50, 100],
          searchQuery : '',
          sortBy : 'number',
          isSortDirDesc :true,
        }
      },
      mounted(){
          this.$root.$on('refresh:button', () => {
              this.refreshProducts();
          });
      },
      watch:{
        'productToEdit.price'(v){
          setTimeout(()=>{
            this.validationErrors = this.$refs.newPrice? this.$refs.newPrice.errors.Price.length>0 : false;
          }, 100);
        },
      },
      computed:{
        localItemsCount(){
          return this.products.length;
        },
        from(){ 
          return  this.perPage * (this.currentPage - 1) + (this.localItemsCount ? 1 : 0);
        },
        to(){  
          return (this.perPage * this.currentPage) < this.localItemsCount ? this.perPage * this.currentPage : this.localItemsCount
        },
        of(){  
          return this.localItemsCount
        },
      },
    
      methods: {
         editProductAction(product, field){
          this.$store.dispatch("catalog/editProduct", product)
          .then((result)=>{
              this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Επιτυχής επεξεργασία ' + field + ((field =='status')? ', η αλλαγή θα εφαρμοστεί εντός 30\u0027' : ''),
                    icon: 'CheckIcon',
                    variant: 'success',
                },
              })
          })
          .catch((e)=>{
            if (field =='status') {
              this.products.find(x=>x.id==this.productToEditId).active = !this.products.find(x=>x.id==this.productToEditId).active
            }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t('An error has occured!'),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })          
          })
        },
        validateNewPrice() {
          if(this.productToEdit.price <= 0){
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t("Η νέα τιμή πρέπει να είναι θετική"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
            }
            else{
              //Keep 2 decimal digits
              var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (2 || -1) + '})?');
              this.productToEdit.price = this.productToEdit.price.toString().match(re)[0];

              this.products.find(x=>x.id==this.productToEditId).price = parseFloat(this.productToEdit.price).toFixed(2)
              
              this.editProductAction(this.products.find(x=>x.id==this.productToEditId),'τιμής');
          }
        },
        validateChangeActive(id){
          this.editProduct(id);
          this.products.find(x=>x.id==id).active = !this.products.find(x=>x.id==id).active;
          this.$bvModal
          .msgBoxConfirm('Σίγουρα θέλετε να αλλάξετε το status του προιόντος;', {
            title: this.$t('Confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$t('Confirm'),
            cancelTitle: this.$t('Cancel'),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(valueTrue => {
            if (valueTrue) {this.changeActive(id)}
            else this.products.find(x=>x.id==id).active = !this.products.find(x=>x.id==id).active //revert check
          })
        },
        changeActive(id){
          this.editProductAction(this.products.find(x=>x.id==id),'status');
        },
        editProduct(id){
          this.productToEdit = Object.assign( {} , this.products.find(x=>x.id==id));
          this.productToEditId = id
        },
        refreshProducts(){

          this.loading = true
          this.$store.dispatch("offers/getProducts")
            .then((data)=>{
                let temp = data.data.result.items
                this.products = temp.map(x=>{ 
                  return {
                    id: x.id,
                    sku:x.sku,
                    name:x.name,
                    category: x.category,
                    price:x.price_info.regular_price,
                    active: x.active
                  }
                })             
            }) 
            .catch(()=>{this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t("Error fetching products"),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })          
            })
            .finally(()=>{
                this.loading = false               
                this.searchQuery=''
                this.currentPage = 1   
                this.keykey++         
            }) 
        }
      },
    
      created() {
    
        this.loading = true;
    
        if (!store.hasModule('offers')) store.registerModule('offers', moduleOffers)
    
        this.$store.dispatch("offers/getProducts")
            .then((data)=>{
                let temp = data.data.result.items
                this.products = temp.map(x=>{ 
                  return {
                    id: x.id,
                    sku:x.sku,
                    name:x.name,
                    category: x.category,
                    price:x.price_info.regular_price,
                    active: x.active
                  }
                })             
            }) 
            .catch(()=>{this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.$t("Error fetching products"),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })          
            })
            .finally(()=>{
                this.loading = false            
            })        
      },
    }
    </script>
    
    
    <style lang="scss">
      @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    